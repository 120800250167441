import React, { useState } from "react";

import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button, Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link, Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { OnErrorFetchResponse, RoleEnum } from "@mcp/interfaces";
import { LoginRequest } from "@mcp/services/src/services";
import { removeObjectEmptyValue } from "@mcp/utils";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import SocialMedia from "@/components/SocialMedia";
import TitleAuth from "@/components/TitleAuth";
import { mApi } from "@/services";

function SignIn({ countryCode }: { countryCode?: string }) {
  const router = useRouter();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [errorCode, setErrorCode] = useState<number | undefined>(undefined);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const loginMutation = useMutation(
    ["loginHttpControllerLogin"],
    (formData: LoginRequest) => mApi.v1.loginHttpControllerLogin(formData),
  );

  const { data, isLoading } = useQuery(
    ["getProfileControllerProfile"],
    () => mApi.v1.getProfileControllerProfile(),
    {
      retry: 0,
      enabled: errorCode !== 401,
      onError: (error) => {
        const err = error as OnErrorFetchResponse;
        setErrorCode(err?.status);
      },

    },
  );
  const profile = data?.data;

  const handleShowPassword = () => setShowPassword(!showPassword);

  const onSignIn = async (data: any) => {
    const formData = {
      username: data.username.trim(),
      password: data.password,
    };

    loginMutation.mutate(formData, {
      onSuccess: async (res) => {
        window.parent.postMessage({ type: "login", success: true }, "*"); // TODO: login with iframe
        const callbackUrl = decodeURIComponent(router?.query?.callback_url as string);
        const isExternal = router.query?.is_external;
        if (isExternal) {
          return router.push("/signin?is_external=true&logged=true");
        }
        await router.push(callbackUrl || redirectByRole(res?.data.role) as string);
      },
      onError: (error: any) => {
        const err = error as OnErrorFetchResponse;

        toast({
          title: err.error.message,
          status: "error",
          position: "top",
        });
      },
    });
  };

  const redirectByRole = (role: string) => {
    switch (role) {
      case RoleEnum.user:
        return process.env.CORE_URL;
      case RoleEnum.creator:
        return process.env.CREATOR_URL;
      case RoleEnum.admin:
        return process.env.ADMIN_URL;
      default:
        return process.env.CORE_URL;
    }
  };

  React.useEffect(() => {
    (async () => {
      if (isLoading) return;
      if (profile) {
        if (router?.query?.callback_url) {
          const callbackUrl = decodeURIComponent(router?.query?.callback_url as string);
          return router.push(callbackUrl as string);
        }
        router.push(redirectByRole(profile.role) as string);
      }
    })();
  }, [profile]);

  return (
    <Box w="full" pos="relative">
      {router.query.is_external && router.query.logged && (
        <Center pos="absolute" inset={0} height="full" width="full">
          <Spinner />
        </Center>
      )}
      <TitleAuth
        title="Sign in"
        subTitle="New to MConsultingPrep?"
        textLink={(
          <Link
            as={NextLink}
            passHref
            href={{
              pathname: "/signup",
              query: removeObjectEmptyValue({ callback_url: router.query?.callback_url, isTrial: router.query?.isTrial }),
            }}
            color="primary"
            ml={1}
          >
            Sign up now!
          </Link>
        )}
      />
      <form
        method="post"
        onSubmit={handleSubmit(onSignIn)}
      >
        <Flex
          align="center"
          border="1px solid rgba(0, 0, 0, 0.1)"
          borderRadius={8}
          boxShadow="0px 2px 10px rgba(0, 0, 0, 0.05)"
          direction="column"
          m="24px 0"
          p={6}
        >
          <FormControl isInvalid={errors.username}>
            <Box mb={6} w="full">
              <FormLabel
                htmlFor="email"
                fontSize={18}
                fontWeight={400}
                color="black.8c"
              >
                Email
              </FormLabel>
              <Input
                id="email"
                borderRadius="6px"
                placeholder="example@email.com"
                size="lg"
                type="text"
                {...register("username", {
                  required: "Please enter a valid email.",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please enter a valid email.",
                  },
                })}
              />
              <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
            </Box>
          </FormControl>
          <FormControl isInvalid={errors.password}>
            <Box mb={6} w="full">
              <FormLabel
                htmlFor="password"
                fontSize={18}
                fontWeight={400}
                color="black.8c"
              >
                Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  id="password"
                  borderRadius="6px"
                  placeholder=""
                  size="lg"
                  type={showPassword ? "text" : "password"}
                  {...register("password", {
                    required: "The password is incorrect.",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <InputRightElement width="4.5rem" h="full">
                  <IconButton
                    isRound
                    aria-label="show password"
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    size="sm"
                    variant="ghost"
                    onClick={handleShowPassword}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            </Box>
          </FormControl>

          <Box mb={6} w="full">
            <Flex align="center">
              <Link as={NextLink} passHref href="/forgot-password" color="primary" fontSize={18} mr="32px">
                Forgot your password?
              </Link>
              <Button
                fontSize={16}
                size="lg"
                type="submit"
                isLoading={loginMutation.isLoading || isSubmitting}
              >
                Sign in
              </Button>
            </Flex>
          </Box>

          <Box mb={6} padding="11px 0" position="relative" w="full">
            <Divider orientation="horizontal" />
            <Text
              background="white"
              fontSize={18}
              bottom={0}
              color="black.26"
              display="inline-block"
              left="50%"
              p="0 12px"
              position="absolute"
              transform="translateX(-50%)"
            >
              or
            </Text>
          </Box>

          <SocialMedia countryCode={countryCode} />
        </Flex>
      </form>
    </Box>
  );
}

export default SignIn;
