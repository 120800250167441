// src/enums.ts
var FormatEnum = /* @__PURE__ */ ((FormatEnum2) => {
  FormatEnum2["interviewer_led"] = "Interviewer-led";
  FormatEnum2["candidate_led"] = "Candidate-Led";
  FormatEnum2["bcg_casey_simulation"] = "Casey";
  return FormatEnum2;
})(FormatEnum || {});
var StatusEnum = /* @__PURE__ */ ((StatusEnum2) => {
  StatusEnum2["draft"] = "Draft";
  StatusEnum2["published"] = "Published";
  return StatusEnum2;
})(StatusEnum || {});
var OptionGroup = /* @__PURE__ */ ((OptionGroup2) => {
  OptionGroup2["blockType"] = "block_type";
  OptionGroup2["caseType"] = "case_type";
  OptionGroup2["caseStatus"] = "case_status";
  OptionGroup2["fileUpload"] = "file_upload";
  OptionGroup2["questionEdgeType"] = "question_edge_type";
  OptionGroup2["questionFormat"] = "question_format";
  OptionGroup2["sessionMessageType"] = "session_message_type";
  return OptionGroup2;
})(OptionGroup || {});
var CaseInfoItem = /* @__PURE__ */ ((CaseInfoItem2) => {
  CaseInfoItem2["key"] = "Case ID";
  CaseInfoItem2["name"] = "Case name";
  CaseInfoItem2["client"] = "Client name";
  CaseInfoItem2["industry"] = "Industry";
  CaseInfoItem2["func"] = "Function";
  CaseInfoItem2["type"] = "Format";
  CaseInfoItem2["duration"] = "Time limit";
  return CaseInfoItem2;
})(CaseInfoItem || {});
var ConditionEnum = /* @__PURE__ */ ((ConditionEnum2) => {
  ConditionEnum2["true"] = "If Right";
  ConditionEnum2["false"] = "If Wrong";
  return ConditionEnum2;
})(ConditionEnum || {});
var ActionNextStepEnum = /* @__PURE__ */ ((ActionNextStepEnum2) => {
  ActionNextStepEnum2["to_question"] = "Go to question";
  ActionNextStepEnum2["to_block"] = "Go to block";
  return ActionNextStepEnum2;
})(ActionNextStepEnum || {});
var BlockTreeTypeEnum = /* @__PURE__ */ ((BlockTreeTypeEnum2) => {
  BlockTreeTypeEnum2["problem"] = "Problem";
  BlockTreeTypeEnum2["branch"] = "Branch";
  BlockTreeTypeEnum2["yellow_branch"] = "Yellow branch";
  BlockTreeTypeEnum2["red_branch"] = "Red Branch";
  BlockTreeTypeEnum2["break"] = "Break";
  BlockTreeTypeEnum2["yellow_break"] = "Yellow break";
  BlockTreeTypeEnum2["red_break"] = "Red break";
  BlockTreeTypeEnum2["root_cause"] = "Root cause";
  BlockTreeTypeEnum2["ilq"] = "Ilq";
  return BlockTreeTypeEnum2;
})(BlockTreeTypeEnum || {});
var RoleEnum = /* @__PURE__ */ ((RoleEnum2) => {
  RoleEnum2["user"] = "user";
  RoleEnum2["admin"] = "administrator";
  RoleEnum2["creator"] = "creator";
  return RoleEnum2;
})(RoleEnum || {});
var LessonTypeEnum = /* @__PURE__ */ ((LessonTypeEnum2) => {
  LessonTypeEnum2["video"] = "video";
  LessonTypeEnum2["pdf"] = "pdf";
  LessonTypeEnum2["html"] = "html";
  LessonTypeEnum2["quiz"] = "quiz";
  return LessonTypeEnum2;
})(LessonTypeEnum || {});
var CategoriesSlugEnum = /* @__PURE__ */ ((CategoriesSlugEnum2) => {
  CategoriesSlugEnum2["career"] = "consulting-career";
  CategoriesSlugEnum2["skills"] = "consulting-skills";
  CategoriesSlugEnum2["resume"] = "consulting-resume";
  CategoriesSlugEnum2["interview"] = "consulting-interview";
  CategoriesSlugEnum2["tests"] = "consulting-tests";
  CategoriesSlugEnum2["companies"] = "21";
  return CategoriesSlugEnum2;
})(CategoriesSlugEnum || {});
export {
  ActionNextStepEnum,
  BlockTreeTypeEnum,
  CaseInfoItem,
  CategoriesSlugEnum,
  ConditionEnum,
  FormatEnum,
  LessonTypeEnum,
  OptionGroup,
  RoleEnum,
  StatusEnum
};
