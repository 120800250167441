import { ReactElement } from "react";

import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { getClientIp } from "request-ip";

import LayoutAuth from "@/components/LayoutAuth";
import SignIn from "@/containers/SignIn";
import { NextPageWithLayout } from "@/interfaces/common";
import { getCountryCodeByIp } from "@/libs/country";

const SignInPage: NextPageWithLayout = ({ countryCode }: InferGetServerSidePropsType<any>) => {
  return <SignIn countryCode={countryCode} />;
};

SignInPage.getLayout = function getLayout(page: ReactElement) {
  return <LayoutAuth title="Sign In">{page}</LayoutAuth>;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const detectedIp = getClientIp(context.req);
  const countryCode = await getCountryCodeByIp(detectedIp);

  return {
    props: { countryCode },
  };
};

export default SignInPage;
